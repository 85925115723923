import type { JobAdClient } from '../types/jobAd';
import { jamFormStepApplicationServiceSetup } from './useJamFormStepApplication';
import { jamFormStepContentServiceSetup } from './useJamFormStepContent';
import { jamFormStepDataServiceSetup } from './useJamFormStepData';
import { jamFormStepDesignServiceSetup } from './useJamFormStepDesign';

const duplicatedJob = ref<JobAdClient | null>(null);

export const useDuplicateJob = () => {
    const setDuplicatedJob = (job: JobAdClient | null) => {
        duplicatedJob.value = job;
        localStorage.setItem('duplicatedJob', JSON.stringify(job));
    };

    if (duplicatedJob.value === null && process.client) {
        const storedJob = localStorage?.getItem('duplicatedJob');
        let parsedJob = null;
        if (storedJob) parsedJob = JSON.parse(storedJob);
        if (
            !parsedJob ||
            !parsedJob.duplicationId ||
            parsedJob.duplicationId !== useRoute().params.id
        ) {
            if (parsedJob && !parsedJob.duplicationId) setDuplicatedJob(null);
            duplicatedJob.value = null;
        } else {
            duplicatedJob.value = parsedJob.job;
        }
    }

    const setDuplicatedJobId = (jobId: string) => {
        if (duplicatedJob.value === null) return;
        const duplicatedJobWithId = {
            duplicationId: jobId,
            job: duplicatedJob.value,
        };
        localStorage.setItem(
            'duplicatedJob',
            JSON.stringify(duplicatedJobWithId),
        );
    };

    const existsDuplicateJob = () => {
        return duplicatedJob.value !== null;
    };

    const getDuplicatedJobFormStepData = () => {
        if (duplicatedJob.value === null) return null;
        return jamFormStepDataServiceSetup.outbound(duplicatedJob.value);
    };

    const getDuplicatedJobFormStepContent = () => {
        if (duplicatedJob.value === null) return null;
        return jamFormStepContentServiceSetup.outbound(duplicatedJob.value);
    };

    const getDuplicatedJobFormStepApplication = () => {
        if (duplicatedJob.value === null) return null;
        return jamFormStepApplicationServiceSetup.outbound(duplicatedJob.value);
    };

    const getDuplicatedJobFormStepDesign = () => {
        if (duplicatedJob.value === null) return null;
        return jamFormStepDesignServiceSetup.outbound(duplicatedJob.value);
    };

    return {
        existsDuplicateJob,
        getDuplicatedJobFormStepApplication,
        getDuplicatedJobFormStepContent,
        getDuplicatedJobFormStepData,
        getDuplicatedJobFormStepDesign,
        setDuplicatedJob,
        setDuplicatedJobId,
    };
};
