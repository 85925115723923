import type { FileData } from '../types/file';
import type { JobAdFileType } from '../types/jobAd';

export type JamFileUploadFile = {
    file: File;
    uuid: string;
    relativeUrl: string;
};
export const useJamFileUpload = (setup: {
    jobAdId: number;
    onUpload?: (uuids: JamFileUploadFile[]) => void;
}) => {
    const { isInternalUser } = useUser();
    const { error } = useNotification();
    const ENDPOINTS = {
        copy: `/api/manage-job-ads/shared/files/copy`,
        delete: isInternalUser.value
            ? `/api/manage-job-ads/internal/job-ads/${setup.jobAdId}/files/[uuid]`
            : `/api/manage-job-ads/external/job-ads/${setup.jobAdId}/files/[uuid]`,
        download: isInternalUser.value
            ? `/api/manage-job-ads/internal/job-ads/${setup.jobAdId}/files/[uuid]/download`
            : `/api/manage-job-ads/external/job-ads/${setup.jobAdId}/files/[uuid]/download`,
        getAll: isInternalUser.value
            ? `/api/manage-job-ads/internal/job-ads/${setup.jobAdId}/files/[uuid]`
            : `/api/manage-job-ads/external/job-ads/${setup.jobAdId}/files/[uuid]`,
        upload: `/api/manage-job-ads/shared/files`,
    };

    const files = ref<JamFileUploadFile[]>([]);
    const filesInfo = ref<FileData[]>([]);

    const fetchFilesInfo = async (
        files: (JamFileUploadFile | JobAdFileType)[],
    ): Promise<FileData[]> => {
        const filteredFileUuids = files.filter((file) => file.uuid !== '');

        if (filteredFileUuids.length === 0) {
            return [];
        }

        const getListEndpoint = ENDPOINTS.getAll.replace(
            '[uuid]',
            filteredFileUuids.map((file) => file.uuid).join(','),
        );

        return await $fetch<FileData[]>(getListEndpoint);
    };

    const setDefaultFiles = async (uploadedFiles: JobAdFileType[]) => {
        files.value = [];
        filesInfo.value = (await fetchFilesInfo(uploadedFiles)) || [];
        for (const fileInfo of filesInfo.value) {
            if (!fileInfo || !fileInfo.uuid) {
                continue;
            }

            // Create a placeholder file with the correct name and size based on metadata
            const file = new File(
                [new ArrayBuffer(fileInfo.size || 0)],
                fileInfo.name,
            );
            files.value.push({
                file,
                relativeUrl: fileInfo.url ?? '',
                uuid: fileInfo.uuid,
            });
        }
    };

    const isUploadedAlready = (file: File, uuid?: string | null): boolean => {
        if (!uuid) {
            return false;
        }

        const alreadyUploadedFile = filesInfo.value.find(
            (f) => f.uuid === uuid,
        );

        if (!alreadyUploadedFile) {
            return false;
        }

        return (
            alreadyUploadedFile.name === file.name &&
            alreadyUploadedFile.size === file.size
        );
    };

    const upload = async () => {
        // Refresh files info to make sure we have the latest data on upload
        filesInfo.value = await fetchFilesInfo(files.value);

        if (files.value.length === 0) {
            for (const file of files.value) {
                await deleteFile(file.uuid);
            }
            files.value = [];
            return;
        }

        for (const [index, fileItem] of files.value.entries()) {
            if (isUploadedAlready(fileItem.file, fileItem.uuid)) {
                if (setup.onUpload) {
                    setup.onUpload(files.value);
                }
                continue;
            }

            await deleteFile(fileItem.uuid);

            const uploadedFile = await uploadFile(fileItem.file);
            files.value[index].uuid = uploadedFile?.uuid ?? '';
            files.value[index].relativeUrl = uploadedFile?.url ?? '';

            if (setup.onUpload) {
                setup.onUpload(files.value);
            }
        }
    };

    const uploadFile = async (file: File): Promise<FileData> => {
        const formData = new FormData();
        formData.append('file', file);
        const uploadResult = await $fetch<FileData[]>(ENDPOINTS.upload, {
            body: formData,
            method: 'post',
        });

        return uploadResult[0];
    };

    const deleteFile = async (uuid: string): Promise<void> => {
        if (uuid === '') {
            return;
        }

        const deleteEndpoint = ENDPOINTS.delete.replace('[uuid]', uuid);

        await $fetch<void>(deleteEndpoint, {
            method: 'delete',
        });
    };

    const copyFiles = async (
        fileUuids: JobAdFileType[],
        context: string,
    ): Promise<FileData[]> => {
        try {
            return await $fetch<FileData[]>(ENDPOINTS.copy, {
                body: { context, files: fileUuids },
                method: 'post',
            });
        } catch (err) {
            error('Fehler beim Kopieren der Datei(en)');
            return [];
        }
    };

    return {
        copyFiles,
        deleteFile,
        fetchFilesInfo,
        files,
        setDefaultFiles,
        upload,
    };
};
