import { z } from 'zod';

import type { JamFormContainerStepperSectionConfig } from '../components/JamFormContainer.vue';
import type { JamFormStepApplication } from '../types/jamForm';
import type { JobAdAttributes } from '../types/jobAd';
import { JobAdStatuses } from '../utils/constants';
import {
    APPLICATION_STANDARD_DOCUMENT_OPTIONS,
    GENERAL_VALIDATION_MESSAGES,
} from '../utils/constants';
import { isDateInFuture } from '../utils/date';
import { useFindDatasetDuplicates } from './useFindDatasetDuplicates';
import { useFormatStringDate } from './useFormatStringDate';
import { useJamForm } from './useJamForm';
import { useJamFormSteps } from './useJamFormSteps';
import type { JamServiceJobAdsSetup } from './useJamServiceJobAds';

const validationMessages = {
    additionalDocumentAlreadyInStandardDocuments:
        'Dieses Dokument ist bereits als vordefinierte Option verfügbar.',
    additionalDocumentInputDuplicates:
        'Dieser Dokumenteintrag existiert bereits.',
    additionalDocumentInputRequired: 'Dokument muss ausgefüllt werden.',
    applicationSelectionStepsDuplicates:
        'Dieser Bewerbungsschritt existiert bereits.',
    applicationSelectionStepsTitle: 'Überschrift muss ausgefüllt werden.',
    atsId: 'ATS-Job-ID muss ausgefüllt werden',
    emailInvalid: 'E-Mail-Adresse muss eine gültig sein.',
    emailRequired: 'E-Mail-Adresse muss ausgefüllt werden.',
    endDateFutureConstraint: GENERAL_VALIDATION_MESSAGES.dateConstraintFuture,
    endDateInvalid: GENERAL_VALIDATION_MESSAGES.dateInvalid,
    endDateRequired: 'Datum muss ausgefüllt werden',
    imageRequired: 'Bild muss hochgeladen werden.',
    jobTitleRequired: 'Berufsbezeichnung muss ausgefüllt werden.',
    nameRequired: 'Name muss ausgefüllt werden.',
    telefonNumberRequired: 'Telefonnummer muss ausgefüllt werden.',
    telephoneNumberInvalid:
        'Telefonnummer muss richtiges Format haben (z.B. +43 (0) 732 12345)',
};

export const zJamFormStepApplication = z
    .object({
        applicationAdditionalDocuments: z
            .array(
                z.object({
                    title: z
                        .string({
                            required_error:
                                validationMessages.additionalDocumentInputRequired,
                        })
                        .min(
                            1,
                            validationMessages.additionalDocumentInputRequired,
                        ),
                }),
            )
            .superRefine((dataList, ctx) => {
                const duplicateIndexes = useFindDatasetDuplicates(dataList, [
                    'title',
                ]);

                if (duplicateIndexes.length > 0) {
                    duplicateIndexes.forEach((index) => {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message:
                                validationMessages.additionalDocumentInputDuplicates,
                            path: [index, 'title'],
                        });
                    });
                }
            })
            .superRefine((dataList, ctx) => {
                const standardDocuments =
                    APPLICATION_STANDARD_DOCUMENT_OPTIONS.map((option) =>
                        option.label.toLowerCase(),
                    );

                dataList.forEach((additionalDocument, index) => {
                    const titleLower = additionalDocument.title.toLowerCase();

                    if (
                        standardDocuments.some((label) =>
                            titleLower.includes(label),
                        )
                    ) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message:
                                validationMessages.additionalDocumentAlreadyInStandardDocuments,
                            path: [index, 'title'],
                        });
                    }
                });
            }),
        applicationApplyToLink: z
            .string({
                invalid_type_error: validationMessages.emailRequired,
                required_error: validationMessages.emailRequired,
            })
            .email({ message: validationMessages.emailInvalid })
            .min(1, validationMessages.emailRequired),
        applicationContactPersonImage: z
            .object({
                relativeUrl: z.string(),
                uuid: z.string().uuid(),
            })
            .nullable()
            .optional(),
        applicationContactPersonJobTitle: z.union([
            z.string().optional(),
            z.null(),
        ]),
        applicationContactPersonName: z.union([
            z.string().optional(),
            z.null(),
        ]),
        applicationContactPersonTelephoneNumber: z.union([
            z
                .string()
                .regex(
                    /^[0-9+()\s]+$/,
                    validationMessages.telephoneNumberInvalid,
                )
                .or(z.literal(''))
                .optional(),
            z.null(),
        ]),

        applicationPeriod: z
            .object({
                deactivateAdWithEndDate: z.boolean(),
                endDate: z.union([
                    z
                        .string()
                        .regex(
                            /^((0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4}))?$/,
                            validationMessages.endDateInvalid,
                        ),
                    z.null(),
                ]),
            })
            .refine(
                (data) => {
                    return data.endDate || !data.deactivateAdWithEndDate;
                },
                {
                    message: validationMessages.endDateRequired,
                    path: ['endDate'],
                },
            ),
        applicationSelectionSteps: z
            .array(
                z
                    .object({
                        description: z.string().optional(),
                        title: z.string(),
                    })
                    .refine(
                        (data) => {
                            return data.title;
                        },
                        {
                            message:
                                validationMessages.applicationSelectionStepsTitle,
                            path: ['title'],
                        },
                    ),
            )
            .superRefine((dataList, ctx) => {
                const duplicateIndexes = useFindDatasetDuplicates(dataList, [
                    'title',
                ]);

                if (duplicateIndexes.length > 0) {
                    duplicateIndexes.forEach((index) => {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message:
                                validationMessages.applicationSelectionStepsDuplicates,
                            path: [index, 'title'],
                        });
                    });
                }
            }),
        applicationStandardDocuments: z.array(
            z.enum([
                'CV',
                'MOTIVATION-LETTER',
                'DRIVING-LICENCE',
                'CRIMINAL-RECORD-CERTIFICATE',
            ]),
        ),
        atsId: z.union([z.string(), z.null()]),
        atsIntegration: z.boolean().optional(),
        meta: z
            .object({
                status: z.enum(JobAdStatuses).optional(),
            })
            .optional(),
    })
    .superRefine((data, ctx) => {
        const {
            applicationContactPersonName,
            applicationContactPersonJobTitle,
            applicationContactPersonTelephoneNumber,
            applicationContactPersonImage,
        } = data;

        if (
            (applicationContactPersonJobTitle ||
                applicationContactPersonTelephoneNumber ||
                applicationContactPersonImage) &&
            !applicationContactPersonName
        ) {
            const filledFields = [
                applicationContactPersonJobTitle && 'Berufsbezeichnung',
                applicationContactPersonTelephoneNumber && 'Telefonnummer',
                applicationContactPersonImage && 'Bild',
            ].filter(Boolean);

            const errorMessage =
                filledFields.length === 1
                    ? `Um ${filledFields[0]} der Ansprechperson zu hinterlegen, muss der Name angegeben werden.`
                    : `Um ${filledFields
                          .slice(0, -1)
                          .join(', ')} und ${filledFields.slice(
                          -1,
                      )} der Ansprechperson zu hinterlegen, muss der Name angegeben werden.`;

            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: errorMessage,
                path: ['applicationContactPersonName'],
            });
        }
    })
    .refine(
        (data) => {
            if (
                data.applicationPeriod.deactivateAdWithEndDate &&
                !data.applicationPeriod.endDate
            ) {
                return true;
            }

            if (data.meta?.status !== 'DRAFT') {
                return true;
            }

            return (
                data.applicationPeriod.endDate === '' ||
                isDateInFuture(data.applicationPeriod.endDate)
            );
        },
        {
            message: validationMessages.endDateFutureConstraint,
            path: ['applicationPeriod.endDate'],
        },
    )
    .refine(
        (data) => {
            return !(data.atsIntegration && !data.atsId);
        },
        {
            message: validationMessages.atsId,
            path: ['atsId'],
        },
    );

export const stepperSectionsApplication: JamFormContainerStepperSectionConfig[] =
    [
        {
            fields: ['applicationApplyToLink'],
            title: 'E-Mail',
        },
        {
            fields: [
                'applicationContactPersonName',
                'applicationContactPersonJobTitle',
                'applicationContactPersonTelephoneNumber',
                'applicationContactPersonImage',
            ],
            optional: true,
            title: 'Ansprechpartner',
        },
        {
            fields: [
                'applicationPeriod.endDate',
                'applicationPeriod.deactivateAdWithEndDate',
            ],
            optional: true,
            title: 'Bewerbungszeitraum',
        },
        {
            fields: [
                'applicationStandardDocuments',
                'applicationAdditionalDocuments',
            ],
            optional: true,
            title: 'Dokumente',
        },
        {
            fields: ['applicationSelectionSteps'],
            optional: true,
            title: 'Bewerbungsschritte',
        },
    ];

export const jamFormStepApplicationValidationErrorsMap: {
    [key: string]: string;
} = {
    'attributes.applicationApplyToLink': 'applicationApplyToLink',
    'attributes.applicationContactPersonJobTitle':
        'applicationContactPersonJobTitle',
    'attributes.applicationContactPersonName': 'applicationContactPersonName',
    'attributes.applicationContactPersonTelephoneNumber':
        'applicationContactPersonTelephoneNumber',
    'attributes.applicationPeriodDeactivateAdWithEndDate':
        'applicationPeriodDeactivateAdWithEndDate',
    'attributes.atsId': 'atsId',
};

export const jamFormStepApplicationServiceSetup: JamServiceJobAdsSetup<JamFormStepApplication> =
    {
        inbound: (data: JamFormStepApplication): JobAdAttributes => {
            return {
                applicationAdditionalDocuments:
                    data.applicationAdditionalDocuments?.map(
                        (item) => item.title,
                    ),
                applicationApplyToLink: data.applicationApplyToLink,
                applicationContactPersonImage:
                    data.applicationContactPersonImage,
                applicationContactPersonJobTitle:
                    data.applicationContactPersonJobTitle,
                applicationContactPersonName: data.applicationContactPersonName,
                applicationContactPersonTelephoneNumber:
                    data.applicationContactPersonTelephoneNumber,
                applicationPeriodDeactivateAdWithEndDate:
                    data.applicationPeriod?.deactivateAdWithEndDate ?? false,
                applicationPeriodEndDate: useFormatStringDate().localToServer(
                    data.applicationPeriod?.endDate ?? undefined,
                ),
                applicationSelectionSteps: data.applicationSelectionSteps,
                applicationStandardDocuments: data.applicationStandardDocuments,
                atsId: data.atsId,
                atsIntegration: data.atsIntegration,
            };
        },
        outbound: (attributes: JobAdAttributes): JamFormStepApplication => {
            return {
                applicationAdditionalDocuments:
                    attributes.applicationAdditionalDocuments?.map((item) => {
                        return { title: item };
                    }),
                applicationApplyToLink: attributes.applicationApplyToLink,
                applicationContactPersonImage:
                    attributes.applicationContactPersonImage,
                applicationContactPersonJobTitle:
                    attributes.applicationContactPersonJobTitle,
                applicationContactPersonName:
                    attributes.applicationContactPersonName,
                applicationContactPersonTelephoneNumber:
                    attributes.applicationContactPersonTelephoneNumber,
                applicationPeriod: {
                    deactivateAdWithEndDate:
                        attributes.applicationPeriodDeactivateAdWithEndDate,
                    endDate: useFormatStringDate().serverToLocal(
                        attributes.applicationPeriodEndDate,
                    ),
                },
                applicationSelectionSteps: attributes.applicationSelectionSteps,
                applicationStandardDocuments:
                    attributes.applicationStandardDocuments,
                atsId: attributes.atsId,
                atsIntegration: attributes.atsIntegration,
            };
        },
        validationErrorsMap: jamFormStepApplicationValidationErrorsMap,
    };

export const jamFormStepApplicationDefaultData: JamFormStepApplication = {
    applicationAdditionalDocuments: [],
    applicationApplyToLink: '',
    applicationContactPersonImage: null,
    applicationContactPersonJobTitle: '',
    applicationContactPersonName: '',
    applicationContactPersonTelephoneNumber: '',
    applicationPeriod: {
        deactivateAdWithEndDate: false,
        endDate: '',
    },
    applicationSelectionSteps: [],
    applicationStandardDocuments: [],
    atsId: '',
    atsIntegration: false,
};

export const useJamFormStepApplication = (
    defaultData?: JamFormStepApplication,
) => {
    const { data, errors, hasError, submit, validate } =
        useJamForm<JamFormStepApplication>({
            defaultData: {
                ...structuredClone(jamFormStepApplicationDefaultData),
                ...defaultData,
            },
            validationRules: [zJamFormStepApplication],
        });

    // TODO: Fix getAjaxSetup typing
    const update = async (id: number, nextPage?: string) => {
        await submit(
            `/api/manage-job-ads/shared/job-ads/${id}/step-application`,
            'patch',
            useJamFormSteps().getAjaxSetup(nextPage) as any,
        );
    };

    return {
        data,
        errors,
        hasError,
        submit,
        update,
        validate,
    };
};
