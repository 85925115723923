export const useFormatStringDate = () => {
    const localToServer = (
        dateString: string | undefined,
    ): string | undefined | null => {
        if (typeof dateString === 'undefined') {
            return undefined;
        }
        if (dateString === '') {
            return null;
        }
        const [day, month, year] = dateString.split('.');
        return `${year}-${month}-${day}`;
    };

    const serverToLocal = (
        dateString: string | undefined | null,
    ): string | undefined => {
        if (!dateString) {
            return '';
        }
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year}`;
    };

    return { localToServer, serverToLocal };
};
