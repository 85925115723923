import { z } from 'zod';

import type { JamFormContainerStepperSectionConfig } from '../components/JamFormContainer.vue';
import type { JamFormStepContent } from '../types/jamForm';
import type { JobAdAttributes } from '../types/jobAd';
import { useFindDatasetDuplicates } from './useFindDatasetDuplicates';
import { useJamFormSteps } from './useJamFormSteps';
import type { JamServiceJobAdsSetup } from './useJamServiceJobAds';

const validationMessages = {
    benefitDuplicates: 'Dieser Eintrag existiert bereits.',
    contentHeadline: 'Überschrift des Inserats muss ausgefüllt werden.',
    contentRequirementsText: 'Anforderungsbeschreibung muss ausgefüllt werden.',
    contentSectionHeadlineRequired:
        'Überschrift für den Abschnitt muss ausgefüllt werden.',
    contentTasksText: 'Aufgabenbeschreibung muss ausgefüllt werden.',
};

export const zJamFormStepContentBenefit = z.object({
    description: z.string().nullable(),
    id: z.string(),
});

export const zJamFormStepContentBenefits = z
    .array(zJamFormStepContentBenefit)
    .refine(
        (dataList) => {
            return useFindDatasetDuplicates(dataList, ['id']).length === 0;
        },
        (dataList) => {
            const lastIndex = useFindDatasetDuplicates(dataList, ['id']).at(-1);
            return {
                message: validationMessages.benefitDuplicates,
                path: [lastIndex ?? 0, 'id'],
            };
        },
    );

export const zJamFormStepContent = z
    .object({
        benefits: zJamFormStepContentBenefits,
        contentClosingText: z.string(),
        contentHeadline: z.string().min(1, validationMessages.contentHeadline),
        contentIntroduction: z.string(),
        contentOfferHeadline: z.string(),
        contentOfferText: z.string(),
        contentRequirementsHeadline: z
            .string()
            .min(1, validationMessages.contentSectionHeadlineRequired),
        contentRequirementsText: z
            .string()
            .min(1, validationMessages.contentRequirementsText),
        contentSubHeadline: z.string(),
        contentTasksHeadline: z
            .string()
            .min(1, validationMessages.contentSectionHeadlineRequired),
        contentTasksText: z
            .string()
            .min(1, validationMessages.contentTasksText),
    })
    .refine(
        (data) => {
            return data.contentOfferHeadline || !data.contentOfferText;
        },
        {
            message: validationMessages.contentSectionHeadlineRequired,
            path: ['contentOfferHeadline'],
        },
    );

export const stepperSectionsContent: JamFormContainerStepperSectionConfig[] = [
    {
        fields: ['contentHeadline'],
        title: 'Überschrift',
    },
    {
        fields: ['contentIntroduction'],
        optional: true,
        title: 'Einleitungstext',
    },
    {
        fields: ['contentTasksHeadline', 'contentTasksText'],
        title: 'Aufgaben',
    },
    {
        fields: ['contentRequirementsHeadline', 'contentRequirementsText'],
        title: 'Anforderungen',
    },
    {
        fields: ['contentOfferHeadline', 'contentOfferText'],
        optional: true,
        title: 'Angebot',
    },
    {
        fields: ['benefits'],
        optional: true,
        title: 'Benefits',
    },
    {
        fields: ['contentClosingText'],
        optional: true,
        title: 'Abschlusstext',
    },
];

export const jamFormStepContentValidationErrorsMap: { [key: string]: string } =
    {
        'attributes.benefits': 'benefits',
        'attributes.contentClosingText': 'contentClosingText',
        'attributes.contentHeadline': 'contentHeadline',
        'attributes.contentIntroduction': 'contentIntroduction',
        'attributes.contentOfferHeadline': 'contentOfferHeadline',
        'attributes.contentOfferText': 'contentOfferText',
        'attributes.contentRequirementsHeadline': 'contentRequirementsHeadline',
        'attributes.contentRequirementsText': 'contentRequirementsText',
        'attributes.contentSubHeadline': 'contentSubHeadline',
        'attributes.contentTasksHeadline': 'contentTasksHeadline',
        'attributes.contentTasksText': 'contentTasksText',
    };

export const jamFormStepContentServiceSetup: JamServiceJobAdsSetup<JamFormStepContent> =
    {
        inbound: (data: JamFormStepContent): JobAdAttributes => {
            return {
                benefits: data.benefits,
                contentClosingText: data.contentClosingText,
                contentHeadline: data.contentHeadline,
                contentIntroduction: data.contentIntroduction,
                contentOfferHeadline: data.contentOfferHeadline,
                contentOfferText: data.contentOfferText,
                contentRequirementsHeadline: data.contentRequirementsHeadline,
                contentRequirementsText: data.contentRequirementsText,
                contentSubHeadline: data.contentSubHeadline,
                contentTasksHeadline: data.contentTasksHeadline,
                contentTasksText: data.contentTasksText,
            };
        },
        outbound: (attributes: JobAdAttributes): JamFormStepContent => {
            const translate = (en: string, de: string) =>
                attributes.language === 'EN' ? en : de;
            return {
                benefits: attributes.benefits ?? [],
                contentClosingText: attributes.contentClosingText || '',
                contentHeadline:
                    attributes.contentHeadline || attributes.title || '',
                contentIntroduction: attributes.contentIntroduction || '',
                contentOfferHeadline:
                    attributes.contentOfferHeadline ||
                    translate('Offer', 'Angebot'),
                contentOfferText: attributes.contentOfferText || '',
                contentRequirementsHeadline:
                    attributes.contentRequirementsHeadline ||
                    translate('Requirements', 'Anforderungen'),
                contentRequirementsText:
                    attributes.contentRequirementsText || '',
                contentSubHeadline: attributes.contentSubHeadline || '',
                contentTasksHeadline:
                    attributes.contentTasksHeadline ||
                    translate('Tasks', 'Aufgaben'),
                contentTasksText: attributes.contentTasksText || '',
            };
        },
        validationErrorsMap: jamFormStepContentValidationErrorsMap,
    };

export const jamFormStepContentDefaultData: JamFormStepContent = {
    benefits: [],
    contentClosingText: '',
    contentHeadline: '',
    contentIntroduction: '',
    contentOfferHeadline: '',
    contentOfferText: '',
    contentRequirementsHeadline: '',
    contentRequirementsText: '',
    contentSubHeadline: '',
    contentTasksHeadline: '',
    contentTasksText: '',
};

export const useJamFormStepContent = (defaultData?: JamFormStepContent) => {
    const { data, errors, hasError, submit, validate } =
        useJamForm<JamFormStepContent>({
            defaultData: {
                ...structuredClone(jamFormStepContentDefaultData),
                ...defaultData,
            },
            validationRules: [zJamFormStepContent],
        });

    // TODO: Fix getAjaxSetup typing
    const update = async (id: number, nextPage?: string) => {
        await submit(
            `/api/manage-job-ads/shared/job-ads/${id}/step-content`,
            'patch',
            useJamFormSteps().getAjaxSetup(nextPage),
        );
    };

    return {
        data,
        errors,
        hasError,
        submit,
        update,
        validate,
    };
};
