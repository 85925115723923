import { useRouter } from 'vue-router';

import type { JamForm } from '../types/jamForm';
import type { JobAdStatus } from '../types/jobAd';
import type { JamServiceResponse } from './useJamService';
import { useNotification } from './useNotification';

export const useJamFormSteps = () => {
    const { error, info } = useNotification();
    const messages = {
        hasValidationErrors:
            'Bitte korrigiere die Eingaben, bevor fortgefahren werden kann',
        published: 'Änderung erfolgreich veröffentlicht',
        saved: 'Entwurf erfolgreich gespeichert',
    };

    const router = useRouter();

    const getSuccessMessage = (
        jobAdStatus: JobAdStatus | undefined,
    ): string => {
        if (jobAdStatus === 'ACTIVE' || jobAdStatus === 'INACTIVE') {
            return messages.published;
        }
        return messages.saved;
    };

    const getAjaxSetup = (
        nextPage?: string,
        onSuccess?: (response: JamServiceResponse<JamForm>) => void,
    ) => ({
        onFail: (response: JamServiceResponse<JamForm>) => {
            error('Fehler ' + response.errorCode);
        },
        onSuccess: (response: JamServiceResponse<JamForm>) => {
            info(getSuccessMessage(response.data?.meta?.status));

            if (onSuccess) {
                onSuccess(response);
            }

            if (nextPage) {
                navigateTo(nextPage);
            }
        },
        onValidationError: () => {
            error(messages.hasValidationErrors);
        },
    });

    return {
        getAjaxSetup,
        messages,
    };
};
