/**
 * Checks the contrast ratio between two given hex colors and returns true if it meets WCAG AA standard (at least 4.5:1).
 */

const hexToRgb = (hex: string) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              b: parseInt(result[3], 16),
              g: parseInt(result[2], 16),
              r: parseInt(result[1], 16),
          }
        : null;
};

const relativeLuminance = (rgb: { r: number; g: number; b: number }) => {
    const sRGB = [rgb.r / 255, rgb.g / 255, rgb.b / 255];
    const luminance = sRGB.map(function (c) {
        return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
    });
    return (
        0.2126 * luminance[0] + 0.7152 * luminance[1] + 0.0722 * luminance[2]
    );
};

const contrastRatio = (lum1: number, lum2: number) => {
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
};

const isValidContrast = (hex1: string, hex2: string) => {
    const rgb1 = hexToRgb(hex1);
    const rgb2 = hexToRgb(hex2);

    if (!rgb1 || !rgb2) {
        throw new Error('Invalid hex color format');
    }

    const lum1 = relativeLuminance(rgb1);
    const lum2 = relativeLuminance(rgb2);

    const ratio = contrastRatio(lum1, lum2);

    // AA standard ratio is 4.5:1
    return ratio >= 4.5;
};

export default isValidContrast;
