import type { FileData } from '../types/file';
import type { JobAdFileType } from '../types/jobAd';

export const copyImages = async (
    imageData: JobAdFileType[],
    context: string,
    copyFiles: (
        fileUuids: JobAdFileType[],
        context: string,
    ) => Promise<FileData[]>,
): Promise<JobAdFileType[]> => {
    if (!imageData || (Array.isArray(imageData) && imageData.length === 0)) {
        return [];
    }

    const newFiles = await copyFiles(imageData, context);

    return newFiles.map(
        (item) =>
            ({
                ...item,
                relativeUrl: item.url,
            }) as JobAdFileType,
    );
};
