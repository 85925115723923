export const useFindDatasetDuplicates = (
    list: { [key: string]: any }[],
    keys: string[],
): number[] => {
    const duplicates: any[] = [];
    const duplicateIndexes: number[] = [];
    for (const index in list) {
        const values: string[] = [];
        keys.forEach((key) => {
            if (list[index]?.[key]) {
                values.push(list[index][key].toString().toLowerCase());
            }
        });
        if (duplicates.includes(values.join('.'))) {
            duplicateIndexes.push(parseInt(index));
            continue;
        }
        duplicates.push(values.join('.'));
    }
    return duplicateIndexes;
};
