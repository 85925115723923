export const useDecimalConverter = () => {
    const convertDotToComma = (
        value: number | null | undefined,
    ): string | null => {
        if (value === null || value === undefined) {
            return null;
        }
        const concertedValue = value.toString().replace('.', ',');

        if (
            concertedValue.split(',')[1] &&
            concertedValue.split(',')[1].length === 1
        ) {
            return concertedValue + '0';
        }
        return concertedValue;
    };

    const convertCommaToDot = (
        value: string | null | undefined,
    ): number | null => {
        if (value === null || value === undefined) {
            return null;
        }

        return parseFloat(value.replace(',', '.'));
    };

    return {
        convertCommaToDot,
        convertDotToComma,
    };
};
