import { z } from 'zod';

import { OFileType } from '../types/file';
import type { JamForm, JamFormStepDesign } from '../types/jamForm';
import type { JobAdAttributes } from '../types/jobAd';
import type { JobAdProductPackageName } from '../types/jobAdProduct';
import type { JobAdQaSections } from '../types/jobAdQa';
import { VALIDATION_CHAR_LIMITS } from '../utils/constants';
import { copyImages } from '../utils/copyImages';
import { useJamForm } from './useJamForm';
import { useJamFormSteps } from './useJamFormSteps';
import type { JamServiceJobAdsSetup } from './useJamServiceJobAds';

const validationMessages = {
    invalidColorContrast:
        'Der Farbkontrast zum Hintergrund ist unzureichend. Bitte wählen Sie eine andere Farbe.',
    invalidColorFormat: 'Bitte geben Sie eine gültige Farbe an.',
    invalidVideoLink: 'Der Link muss ein gültiger Youtube oder Vimeo Link sein',
};

export const zJamFormStepDesign = z
    .object({
        layoutLogoImage: z
            .object({
                relativeUrl: z.string(),
                uuid: z.string().uuid(),
            })
            .nullable()
            .optional(),
        layoutSettingsBackgroundColor: z
            .string()
            .regex(/^#[0-9A-F]{6}$/i, validationMessages.invalidColorFormat),
        layoutSettingsHeaderImages: z.array(
            z.object({
                relativeUrl: z.string(),
                uuid: z.string().uuid(),
            }),
        ),
        layoutSettingsHeadlineColor: z
            .string()
            .regex(/^#[0-9A-F]{6}$/i, validationMessages.invalidColorFormat),
        layoutSettingsListIconColor: z
            .string()
            .regex(/^#[0-9A-F]{6}$/i, validationMessages.invalidColorFormat),
        layoutSettingsListIconStyle: z.enum([
            'FILLED-CIRCLE',
            'RECTANGLE',
            'ARROW',
            'BORDER-CIRCLE',
        ]),
        layoutSettingsPhotoGalleryImages: z.array(
            z.object({
                relativeUrl: z.string(),
                uuid: z.string().uuid(),
            }),
        ),
        layoutSettingsPhotoGalleryPresentation: z.enum([
            'DIFFERENT',
            'UNIFORMLY',
        ]),
        layoutSettingsTextColor: z
            .string()
            .regex(/^#[0-9A-F]{6}$/i, validationMessages.invalidColorFormat),
        layoutSettingsVideo: z
            .string()
            .regex(
                /^((?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:\w+\/)?|album\/(?:\d+\/)?video\/|video\/|)(\d+)|player\.vimeo\.com\/video\/)([a-zA-Z0-9_-]{11}|\d+))?$/,
                validationMessages.invalidVideoLink,
            )
            .optional(),
        product: z.string().optional(),
    })
    .refine(
        (data) => {
            return isValidContrast(
                data.layoutSettingsBackgroundColor,
                data.layoutSettingsHeadlineColor,
            );
        },
        {
            message: validationMessages.invalidColorContrast,
            path: ['layoutSettingsHeadlineColor'],
        },
    )
    .refine(
        (data) => {
            return isValidContrast(
                data.layoutSettingsBackgroundColor,
                data.layoutSettingsTextColor,
            );
        },
        {
            message: validationMessages.invalidColorContrast,
            path: ['layoutSettingsTextColor'],
        },
    )
    .refine(
        (data) => {
            return data.layoutSettingsHeaderImages.length <= 1;
        },
        {
            message: 'Bitte überprüfen Sie die Anzahl der Bilder',
            path: ['layoutSettingsHeaderImages'],
        },
    )
    .refine(
        (data) => {
            return data.layoutSettingsPhotoGalleryImages.length <= 3;
        },
        {
            message: 'Bitte überprüfen Sie die Anzahl der Bilder',
            path: ['layoutSettingsPhotoGalleryImages'],
        },
    );

export const jamFormStepDesignDefaultStyling: JamFormStepDesign = {
    layoutSettingsBackgroundColor: '#FFFFFF',
    layoutSettingsHeaderImages: [],
    layoutSettingsHeadlineColor: '#142542',
    layoutSettingsListIconColor: '#142542',
    layoutSettingsListIconStyle: 'FILLED-CIRCLE',
    layoutSettingsPhotoGalleryImages: [],
    layoutSettingsPhotoGalleryPresentation: 'DIFFERENT',
    layoutSettingsTextColor: '#142542',
    layoutSettingsVideo: '',
};
export const jamFormStepDesignServiceSetup: JamServiceJobAdsSetup<JamFormStepDesign> =
    {
        inbound: (data: JamFormStepDesign): JobAdAttributes => {
            return {
                layoutLogoImage: data.layoutLogoImage,
                layoutSettingsBackgroundColor:
                    data.layoutSettingsBackgroundColor,
                layoutSettingsHeaderImages: data.layoutSettingsHeaderImages,
                layoutSettingsHeadlineColor: data.layoutSettingsHeadlineColor,
                layoutSettingsListIconColor: data.layoutSettingsListIconColor,
                layoutSettingsListIconStyle: data.layoutSettingsListIconStyle,
                layoutSettingsPhotoGalleryImages:
                    data.layoutSettingsPhotoGalleryImages,
                layoutSettingsPhotoGalleryPresentation:
                    data.layoutSettingsPhotoGalleryPresentation,
                layoutSettingsTextColor: data.layoutSettingsTextColor,
                layoutSettingsVideo: data.layoutSettingsVideo,
            };
        },
        outbound: (attributes: JobAdAttributes): JamFormStepDesign => {
            return {
                layoutLogoImage: attributes.layoutLogoImage,
                layoutSettingsBackgroundColor:
                    attributes.layoutSettingsBackgroundColor ||
                    jamFormStepDesignDefaultStyling.layoutSettingsBackgroundColor,
                layoutSettingsHeaderImages:
                    attributes.layoutSettingsHeaderImages ??
                    jamFormStepDesignDefaultStyling.layoutSettingsHeaderImages,
                layoutSettingsHeadlineColor:
                    attributes.layoutSettingsHeadlineColor ||
                    jamFormStepDesignDefaultStyling.layoutSettingsHeadlineColor,
                layoutSettingsListIconColor:
                    attributes.layoutSettingsListIconColor ||
                    jamFormStepDesignDefaultStyling.layoutSettingsListIconColor,
                layoutSettingsListIconStyle:
                    attributes.layoutSettingsListIconStyle ||
                    jamFormStepDesignDefaultStyling.layoutSettingsListIconStyle,
                layoutSettingsPhotoGalleryImages:
                    attributes.layoutSettingsPhotoGalleryImages ||
                    jamFormStepDesignDefaultStyling.layoutSettingsPhotoGalleryImages,
                layoutSettingsPhotoGalleryPresentation:
                    attributes.layoutSettingsPhotoGalleryPresentation ||
                    jamFormStepDesignDefaultStyling.layoutSettingsPhotoGalleryPresentation,
                layoutSettingsTextColor:
                    attributes.layoutSettingsTextColor ||
                    jamFormStepDesignDefaultStyling.layoutSettingsTextColor,
                layoutSettingsVideo:
                    attributes.layoutSettingsVideo ||
                    jamFormStepDesignDefaultStyling.layoutSettingsVideo,
            };
        },
    };

export const useJamFormStepDesign = (
    defaultData?: JamFormStepDesign,
    jobAdPackage?: JobAdProductPackageName,
) => {
    const { data, errors, hasError, submit, validate } = useJamForm<
        JamFormStepDesign & { product: string }
    >({
        defaultData: {
            ...jamFormStepDesignDefaultStyling,
            ...defaultData,
        },
        validationRules: [zJamFormStepDesign],
    });

    // TODO: Fix getAjaxSetup typing
    const update = async (id: number, nextPage?: string) => {
        await submit(
            `/api/manage-job-ads/shared/job-ads/${id}/step-design`,
            'patch',
            useJamFormSteps().getAjaxSetup(nextPage),
        );
    };

    const FIELDS: JobAdQaSections = [
        {
            fields: [
                {
                    acceptedFileTypes: [OFileType.jpeg, OFileType.png],
                    imageUploadPreviewCropped: true,
                    label: 'Titelbild',
                    maxDimensions: {
                        height: 2000,
                        width: 2000,
                    },
                    maxFileCount: 1,
                    maxFileSize: 3,
                    minDimensions: {
                        height: 350,
                        width: 1080,
                    },
                    name: 'layoutSettingsHeaderImages',
                    tooltip:
                        'Die Titelbilder werden ganz oben in Ihrem Inserat angezeigt. Werden mehrere Bilder hochgeladen, können Bewerber*innen die Bilder durchklicken. Ein automatisches Umschalten der Bilder ist aus Barrierefreiheitsgründen nicht möglich.',
                    type: 'upload',
                },
                {
                    maxFileCount: 1,
                    name: 'layoutSettingsHeaderImages',
                    type: 'upload-modal',
                },
            ],
            label: `Titelbild (max. 1 Bild)`,
        },
        {
            fields: [
                {
                    acceptedFileTypes: [OFileType.jpeg, OFileType.png],
                    label: 'Logo',
                    maxDimensions: {
                        height: 2000,
                        width: 2000,
                    },
                    maxFileCount: 1,
                    maxFileSize: 3,
                    minDimensions: {
                        height: 32,
                        width: 32,
                    },
                    name: 'layoutLogoImage',
                    type: 'upload',
                },
            ],
            label: 'Logo',
            textAfterLabel: 'max. 1 Bild',
        },
        {
            fields: [
                {
                    label: 'Hintergrundfarbe auswählen',
                    name: 'layoutSettingsBackgroundColor',
                    tooltip:
                        'Wählen Sie die Hintergrundfarbe für Ihr Inserat. Achten Sie darauf, dass sie mit den Textfarben einen barrierefreien Kontrast bildet.',
                    type: 'color',
                },
                {
                    label: 'Textfarbe auswählen',
                    name: 'layoutSettingsTextColor',
                    tooltip:
                        'Wählen Sie die Textfarbe für Ihr Inserat. Achten Sie darauf, dass sie mit der Hintergrundfarbe einen barrierefreien Kontrast bildet.',
                    type: 'color',
                },
                {
                    label: 'Überschriftenfarbe auswählen',
                    name: 'layoutSettingsHeadlineColor',
                    tooltip:
                        'Wählen Sie die Überschriftenfarbe für Ihr Inserat. Achten Sie darauf, dass sie mit der Hintergrundfarbe einen barrierefreien Kontrast bildet.',
                    type: 'color',
                },
            ],
            infoText:
                'Sorgen Sie für ausreichenden <b>Kontrast zwischen Hintergrund- und Textfarben</b>, damit der Text unabhängig von heller oder dunkler Gestaltung gut lesbar bleibt.',
            label: 'Farben',
        },
        {
            fields: [
                {
                    label: 'Aufzählungszeichen',
                    name: 'layoutSettingsListIconStyle',
                    type: 'list-icons',
                },
                {
                    label: 'Farbe auswählen',
                    name: 'layoutSettingsListIconColor',
                    tooltip:
                        'Wählen Sie die Farbe der Aufzählungszeichen für Ihr Inserat.',
                    type: 'color',
                },
            ],
            label: 'Aufzählungszeichen',
        },
        {
            fields: [
                {
                    characterLimit: VALIDATION_CHAR_LIMITS.url.limit,
                    label: 'Videolink',
                    name: 'layoutSettingsVideo',
                    onChange: (value) => {
                        if (value) {
                            const videoRegex =
                                /^((?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:\w+\/)?|album\/(?:\d+\/)?video\/|video\/|)(\d+)|player\.vimeo\.com\/video\/)([a-zA-Z0-9_-]{11}|\d+))(?=[?&]|$)/gm;
                            const match = value.match(videoRegex);
                            if (match) {
                                return match?.[0] || '';
                            } else {
                                return value;
                            }
                        }
                    },
                    placeholder: 'YouTube oder Vimeo Link',
                    tooltip:
                        'Sie können einen Link von YouTube oder Vimeo eingeben, der mit https:// beginnt. Andere Arten von Links werden nicht unterstützt.',
                    type: 'input',
                },
            ],
            label: 'Video',
        },
        {
            fields: [
                {
                    label: '',
                    name: 'layoutSettingsPhotoGalleryPresentation',
                    options: [
                        {
                            label: 'Unterschiedliche Bildgrößen',
                            value: 'DIFFERENT',
                        },
                        {
                            label: 'Einheitliche Bildgrößen',
                            value: 'UNIFORMLY',
                        },
                    ],
                    type: 'radio',
                },
                {
                    acceptedFileTypes: [OFileType.jpeg, OFileType.png],
                    imageUploadPreviewSquare: true,
                    label: 'Galerie',
                    maxDimensions: {
                        height: 2000,
                        width: 2000,
                    },
                    maxFileCount: 3,
                    maxFileSize: 3,
                    minDimensions: {
                        height: 630,
                        width: 850,
                    },
                    name: 'layoutSettingsPhotoGalleryImages',
                    tooltip:
                        'Die Bilder der Galerie werden in Ihrem Inserat angezeigt. Laden Sie Bilder hoch und wählen Sie, ob die Bilder optisch gleich groß, oder in unterschiedlichen Größen dargestellt werden sollen. Im Inserat können Bewerber*innen die Bilder auch anklicken und großformatig durchklicken.',
                    type: 'upload',
                },
                {
                    maxFileCount: 3,
                    name: 'layoutSettingsPhotoGalleryImages',
                    type: 'upload-modal',
                },
            ],
            label: `Galerie (max. 3 Bilder)`,
        },
    ];

    const { copyFiles } = useJamFileUpload({
        jobAdId: Number(data.value.meta?.id),
    });

    const loadDuplicatedJobData = async (duplicateJobData: JamForm) => {
        data.value = {
            ...data.value,
            ...duplicateJobData,
        };

        // Empty images array
        data.value.layoutSettingsHeaderImages = [];
        data.value.layoutSettingsPhotoGalleryImages = [];
        data.value.layoutLogoImage = undefined;

        // Copy images from duplicate job into data
        data.value.layoutSettingsHeaderImages = await copyImages(
            duplicateJobData.layoutSettingsHeaderImages ?? [],
            `seamless-job-ad-${data.value.meta?.uuid as string}`,
            copyFiles,
        );
        data.value.layoutSettingsPhotoGalleryImages = await copyImages(
            duplicateJobData.layoutSettingsPhotoGalleryImages ?? [],
            `seamless-job-ad-${data.value.meta?.uuid as string}`,
            copyFiles,
        );
        const logoCopyResult = await copyImages(
            duplicateJobData.layoutLogoImage
                ? [duplicateJobData.layoutLogoImage]
                : [],
            `seamless-job-ad-${data.value.meta?.uuid as string}`,
            copyFiles,
        );
        if (logoCopyResult?.length > 0) {
            data.value.layoutLogoImage = logoCopyResult[0];
        }
    };

    return {
        FIELDS,
        data,
        errors,
        hasError,
        loadDuplicatedJobData,
        submit,
        update,
        validate,
    };
};
