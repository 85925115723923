import moment from 'moment';
import { z } from 'zod';

import type { JamFormContainerStepperSectionConfig } from '../components/JamFormContainer.vue';
import type {
    JamForm,
    JamFormStepData,
    JamFormStepDataEmploymentType,
} from '../types/jamForm';
import type { JobAdAttributes, JobAdEmploymentType } from '../types/jobAd';
import {
    GENERAL_VALIDATION_MESSAGES,
    LANGUAGE_SKILL_OPTIONS,
    VALIDATION_CHAR_LIMITS,
} from '../utils/constants';
import { JobAdStatuses } from '../utils/constants';
import { isDateInFuture } from '../utils/date';
import { useDecimalConverter } from './useDecimalConverter';
import { useDuplicateJob } from './useDuplicateJob';
import { useFindDatasetDuplicates } from './useFindDatasetDuplicates';
import { useFormatStringDate } from './useFormatStringDate';
import { useJamForm } from './useJamForm';
import { useJamFormSteps } from './useJamFormSteps';
import type { JamServiceResponse } from './useJamService';
import type { JamServiceJobAdsSetup } from './useJamServiceJobAds';

export const validationMessages = {
    averageBonusPerUnitPositive:
        'Durchschnittlicher Bonus muss einen positiven Wert ergeben.',
    dateConstraintEndDateMismatch:
        'Befristet bis muss nach Start Dienstverhältnis liegen',
    dateConstraintFuture: GENERAL_VALIDATION_MESSAGES.dateConstraintFuture,
    dateConstraintNotPast: 'Das Datum darf nicht in der Vergangenheit liegen',
    dateConstraintStartDateMismatch:
        'Start Dienstverhältnis muss vor Befristet bis liegen',
    dateInvalid: GENERAL_VALIDATION_MESSAGES.dateInvalid,
    educationDuplicate: 'Dieser Ausbildungseintrag existiert bereits.',
    jobOpeningsDecimalNumber:
        'Anzahl der offenen Stellen für diese Position darf keine dezimal Zahl sein.',
    jobOpeningsMaxNumber:
        'Anzahl der offenen Stellen für diese Position muss kleiner 100 sein.',
    jobOpeningsPositiveNumber:
        'Anzahl der offenen Stellen für diese Position muss mindestens 1 sein.',
    jobOpeningsRequired:
        'Anzahl der offenen Stellen für diese Position muss ausgefüllt werden.',
    languageDuplicate:
        'Dieser Eintrag für Sprachen Kenntnis existiert bereits.',
    skillAlreadyInPredefinedLanguageSkills:
        'Dieser Kenntnis Eintrag ist bereits als vordefinierte Sprache verfügbar.',
    skillsDuplicate: 'Dieser Kenntnis Eintrag existiert bereits.',
    skillsMax: 'Es können nur maximal 10 Kenntnissen gespeichert werden.',
    skillsSeparatingChars:
        'Es kann nur eine einzelne Kenntnis pro Eintrag angegeben werden. Trennzeichen wie Kommas, Semikolons oder ähnliches sind nicht erlaubt.',
};

const predefinedLanguageSkills = LANGUAGE_SKILL_OPTIONS.map((option) =>
    option.label.toLowerCase(),
);

const parseNumber = (value?: string | null) =>
    useDecimalConverter().convertCommaToDot(value) ?? 0;

const infoText = () => {
    const { user: userInfo } = useUser();

    const companyName = userInfo.value?.user.company.name;
    const companyAddress = `${userInfo.value?.user.company.mainAddress.street} ${userInfo.value.user.company.mainAddress.streetNumber}, ${userInfo.value.user.company.mainAddress.postalCode} ${userInfo.value.user.company.mainAddress.city}`;
    return `Sie sind eingeloggt als <b>${companyName}</b> für den Standort <b>${companyAddress}</b>. Das Inserat wird für das ausgewählte Unternehmen erstellt.`;
};

const zJobEntryDateIsBeforeTemporaryUntilDate = (
    jobEntryDate: string | null | undefined,
    temporaryUntilDate: string | null | undefined,
) => {
    if (!jobEntryDate || !temporaryUntilDate) {
        return true;
    }

    const jobEntryDateObject = moment(jobEntryDate, 'DD.MM.YYYY');
    const temporaryUntilDateObject = moment(temporaryUntilDate, 'DD.MM.YYYY');

    return jobEntryDateObject.isBefore(temporaryUntilDateObject);
};

export const zJamFormStepDataSkills = z
    .array(
        z.object({
            mustHave: z.boolean(),
            title: z
                .string()
                .max(VALIDATION_CHAR_LIMITS.titleGroupItem.limit)
                .refine((title) => !/[;,/|]/.test(title), {
                    message: validationMessages.skillsSeparatingChars,
                }),
        }),
    )
    .max(10, validationMessages.skillsMax)
    .refine(
        (dataList) => {
            return useFindDatasetDuplicates(dataList, ['title']).length === 0;
        },
        (dataList) => {
            const lastIndex = useFindDatasetDuplicates(dataList, ['title']).at(
                -1,
            );
            return {
                message: validationMessages.skillsDuplicate,
                path: [lastIndex ?? 0, 'title'],
            };
        },
    )
    .superRefine((dataList, ctx) => {
        dataList.forEach((skill, index) => {
            const titleLower = skill.title.toLowerCase();

            if (
                predefinedLanguageSkills.some((label) =>
                    titleLower.includes(label),
                )
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message:
                        validationMessages.skillAlreadyInPredefinedLanguageSkills,
                    path: [index, 'title'],
                });
            }
        });
    });

export const zJamFormStepDataRecommendedSkills = z.array(
    z.object({
        mustHave: z.boolean(),
        title: z.string(),
    }),
);

export const zJamFormStepDataEducation = z
    .array(
        z.object({
            mustHave: z.boolean(),
            type: z.string(),
        }),
    )
    .refine(
        (dataList) => {
            return useFindDatasetDuplicates(dataList, ['type']).length === 0;
        },
        (dataList) => {
            const lastIndex = useFindDatasetDuplicates(dataList, ['type']).at(
                -1,
            );
            return {
                message: validationMessages.educationDuplicate,
                path: [lastIndex ?? 0, 'type'],
            };
        },
    );

export const zJamFormStepDataLanguageSkills = z
    .array(
        z.object({
            languageCode: z.string(),
            level: z.enum(['A1', 'A2', 'B1', 'B2', 'C1', 'C2']),
            mustHave: z.boolean(),
        }),
    )
    .refine(
        (dataList) => {
            return (
                useFindDatasetDuplicates(dataList, ['languageCode']).length ===
                0
            );
        },
        (dataList) => {
            const lastIndex = useFindDatasetDuplicates(dataList, [
                'languageCode',
            ]).at(-1);
            return {
                message: validationMessages.languageDuplicate,
                path: [lastIndex ?? 0, 'languageCode'],
            };
        },
    );

export const zJamFormStepDataLocation = z
    .object({
        id: z
            .string({
                invalid_type_error: 'Dienstort muss ausgefüllt werden.',
                required_error: 'Dienstort muss ausgefüllt werden.',
            })
            .min(1, 'Dienstort muss ausgefüllt werden.'),
        name: z.string().min(1, 'Dienstort muss ausgefüllt werden.'),
        number: z.string().nullable(),
        postalCode: z.string().nullable(),
        street: z.string().nullable(),
    })
    .refine(
        (data) => {
            const { street, number, postalCode } = data;
            return postalCode || !(number || street);
        },
        {
            message: 'Postleitzahl muss ausgefüllt werden.',
            path: ['postalCode'],
        },
    )
    .refine(
        (data) => {
            const { street, number } = data;
            return street || !number;
        },
        {
            message: 'Straße muss ausgefüllt werden.',
            path: ['street'],
        },
    )
    .refine(
        (data) => {
            const { street, number } = data;
            return number || !street;
        },
        {
            message: 'Hausnummer muss ausgefüllt werden.',
            path: ['number'],
        },
    );

export const zJamFormStepDataEmploymentType = z
    .object({
        homeofficeDaysPercentage: z
            .number()
            .min(
                1,
                'Homeoffice-Anteil in Prozent muss einen Wert zwischen 1 und 100 ergeben.',
            )
            .max(
                100,
                'Homeoffice-Anteil in Prozent muss einen Wert zwischen 1 und 100 ergeben.',
            )
            .nullable()
            .optional()
            .or(z.literal('')),
        overtimeHandling: z
            .enum([
                'NO-INFORMATION',
                'FLEXIBLE-HOURS',
                'PAID-OVER-HOURS',
                'FLEXIBLE-AND-PAID-OVER-HOURS',
                'OVERHOUR-RATE',
                'ALL-IN',
            ])
            .optional(),
        shiftWork: z.boolean().optional(),
        travelingIsRequired: z.boolean().optional(),
        travelingPercentage: z.union([
            z
                .number()
                .min(0, 'Reisebereitschaft muss einen positiven Wert ergeben.')
                .max(
                    100,
                    'Reisebereitschaft muss kleiner oder gleich 100 sein.',
                )
                .nullable()
                .optional(),
            z.literal(''),
        ]),
        type: z
            .union([
                z.enum([
                    'FIXED-EMPLOYMENT',
                    'INTERNSHIP',
                    'APPRENTICESHIP',
                    'THESIS',
                    'FREELANCE',
                    'STUDENT-JOB',
                ]),
                z.null(),
                z.literal(''),
            ])
            .refine(
                (value) => !!value,
                'Anstellungsart muss ausgefüllt werden.',
            ),
        weeklyHoursMax: z
            .string()
            .regex(
                /^((0|[1-9]\d*)(,\d+)?)?$/,
                'Wochenstunden (bis) müssen einen positiven Wert ergeben.',
            )
            .optional(),
        weeklyHoursMin: z
            .string()
            .regex(
                /^([1-9]\d*(,\d+)?)?$/,
                'Wochenstunden (von) müssen einen positiven Wert ergeben.',
            )
            .optional(),
        workdaysAreFlexible: z.boolean().optional(),
        workdaysInWeek: z
            .array(z.enum(['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']))
            .optional(),
        worktime: z
            .enum(['FULL-TIME', 'PART-TIME', 'MINOR-EMPLOYMENT'])
            .nullable()
            .optional(),
    })
    .superRefine(
        (
            {
                weeklyHoursMin,
                worktime,
                workdaysInWeek,
                workdaysAreFlexible,
                weeklyHoursMax,
            },
            ctx,
        ) => {
            if (!weeklyHoursMin) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Wochenstunden (von) müssen ausgefüllt werden.',
                    path: ['weeklyHoursMin'],
                });
            }

            if (!worktime) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Arbeitszeit muss ausgefüllt werden.',
                    path: ['worktime'],
                });
            }

            if (!workdaysAreFlexible && !workdaysInWeek?.length) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Arbeitstage müssen ausgewählt werden.',
                    path: ['workdaysInWeek'],
                });
            }

            if (
                weeklyHoursMax &&
                parseNumber(weeklyHoursMin) >= parseNumber(weeklyHoursMax)
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message:
                        'Wochenstunden (bis) müssen größer als Wochenstunden (von) sein.',
                    path: ['weeklyHoursMax'],
                });
            }
        },
    );

export const zJamFormStepData = z
    .object({
        contentSalutation: z
            .enum(['FORMAL', 'INFORMAL', 'NEUTRAL', ''], {
                invalid_type_error:
                    'Inhaltliche Anrede muss ausgefüllt werden.',
                required_error: 'Inhaltliche Anrede muss ausgefüllt werden.',
            })
            .refine(
                (value) => !!value,
                'Inhaltliche Anrede muss ausgefüllt werden.',
            ),
        education: zJamFormStepDataEducation,
        employmentRelationship: z
            .object({
                jobEntryDate: z.union([
                    z
                        .string()
                        .regex(
                            /^((0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4}))?$/,
                            validationMessages.dateInvalid,
                        ),
                    z.null(),
                ]),
                jobEntryHasDate: z.boolean(),
                temporaryJob: z.boolean(),
                temporaryUntilDate: z.union([
                    z
                        .string()
                        .regex(
                            /^((0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4}))?$/,
                            validationMessages.dateInvalid,
                        ),
                    z.null(),
                ]),
            })
            .refine(
                (data) => {
                    return !(data.temporaryJob && !data.temporaryUntilDate);
                },
                {
                    message: 'Befristet bis muss ausgefüllt werden',
                    path: ['temporaryUntilDate'],
                },
            )
            .refine(
                (data) => {
                    return !(data.jobEntryHasDate && !data.jobEntryDate);
                },
                {
                    message: 'Start Dienstverhältnis muss ausgefüllt werden',
                    path: ['jobEntryDate'],
                },
            ),
        employmentTypes: z
            .array(zJamFormStepDataEmploymentType)
            .min(1, 'Mindestens eine Anstellungsart ist notwendig')
            .superRefine((dataList, ctx) => {
                const duplicateIndexes = useFindDatasetDuplicates(dataList, [
                    'type',
                    'worktime',
                ]);

                if (duplicateIndexes.length > 0) {
                    duplicateIndexes.forEach((index) => {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message:
                                'Diese Anstellungsart und Arbeitszeit Kombination wurde bereits ausgewählt.',
                            path: [index, 'type'],
                        });
                    });
                }
            }),
        jobFields: z
            .array(z.string(), {
                required_error:
                    'Mindestens eine Positionsebene muss ausgewählt werden',
            })
            .min(1, 'Mindestens ein Berufsfeld muss ausgewählt werden'),

        jobLevels: z
            .array(z.string(), {
                required_error:
                    'Mindestens eine Positionsebene muss ausgewählt werden',
            })
            .min(1, 'Mindestens eine Positionsebene muss ausgewählt werden'),
        jobOpenings: z
            .string()
            .min(1, validationMessages.jobOpeningsRequired)
            .regex(
                /^([0-9]\d*)?$/,
                validationMessages.jobOpeningsRequired, // Ensures it's a integer
            )
            .refine((val) => !val || parseInt(val, 10) > 0, {
                message: validationMessages.jobOpeningsPositiveNumber, // Ensures the number is bigger than 0
            })
            .refine((val) => !val || parseInt(val, 10) <= 100, {
                message: validationMessages.jobOpeningsMaxNumber, // Ensures the number is not bigger than 100
            }),
        language: z.enum(['EN', 'DE']),
        languageSkills: zJamFormStepDataLanguageSkills,
        locations: z
            .array(zJamFormStepDataLocation)
            .min(1, 'Mindestens ein Dienstort ist notwendig')
            .superRefine((dataList, ctx) => {
                const duplicateIndexes = useFindDatasetDuplicates(dataList, [
                    'id',
                    'street',
                    'number',
                    'postalCode',
                ]);

                if (duplicateIndexes.length > 0) {
                    duplicateIndexes.forEach((index) => {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message:
                                'Diese Dienstort wurde bereits ausgewählt.',
                            path: [index, 'id'],
                        });
                    });
                }
            }),
        meta: z
            .object({
                status: z.enum(JobAdStatuses).optional(),
            })
            .optional(),
        product: z.enum(['BASIC', 'PLUS-STARTER', 'PLUS', 'PREMIUM']),
        referenceCode: z.string(),
        salary: z
            .object({
                averageBonusPerUnit: z.string().optional(),
                collectiveAgreement: z.string().optional(),
                collectiveAgreementPerUnitMin: z
                    .string()
                    .regex(
                        /^([1-9]\d*(,\d+)?)?$/,
                        'KV-Mindestbruttogehalt muss einen positiven Wert ergeben.',
                    )
                    .optional(),
                currency: z.enum(['EUR', 'CHF', 'GBP', 'CZK', 'HUF', 'USD'], {
                    invalid_type_error: 'Währung muss ausgewählt werden.',
                    required_error: 'Währung muss ausgewählt werden.',
                }),
                equivalentHours: z
                    .string()
                    .regex(
                        /^([1-9]\d*(,\d+)?)?$/,
                        'Stundenbasis für Gehaltsangabe müssen einen positiven Wert ergeben.',
                    )
                    .optional(),
                isBonusAvailable: z.boolean(),
                numberPerYear: z
                    .union([
                        z
                            .number()
                            .min(
                                0,
                                'Anzahl der Gehälter pro Jahr muss einen positiven Wert ergeben',
                            ),
                        z.literal(''),
                    ])
                    .optional(),
                perUnitMax: z
                    .string()
                    .regex(
                        /^((0|[1-9]\d*)(,\d+)?)?$/,
                        'Maximalgehalt muss einen positiven Wert ergeben.',
                    )
                    .nullable()
                    .optional(),
                perUnitMin: z
                    .string()
                    .regex(
                        /^([1-9]\d*(,\d+)?)?$/,
                        'Bruttofixgehalt muss einen positiven Wert ergeben.',
                    )
                    .nullable()
                    .optional(),
                type: z
                    .enum(['FIXED-SALARY', 'FROM-SALARY', 'RANGED-SALARY'], {
                        invalid_type_error:
                            'Art der Gehaltsangabe muss ausgewählt werden',
                        required_error:
                            'Art der Gehaltsangabe muss ausgewählt werden',
                    })
                    .nullable()
                    .refine(
                        (value) => !!value,
                        'Art der Gehaltsangabe muss ausgewählt werden.',
                    ),
                unit: z.enum(['MONTHLY', 'YEARLY', 'HOURLY', 'DAILY'], {
                    invalid_type_error:
                        'Einheit des Gehalts muss ausgewählt werden',
                    required_error:
                        'Einheit des Gehalts muss ausgewählt werden',
                }),
            })
            .superRefine(
                (
                    {
                        averageBonusPerUnit,
                        collectiveAgreementPerUnitMin,
                        equivalentHours,
                        isBonusAvailable,
                        numberPerYear,
                        perUnitMax,
                        perUnitMin,
                        type,
                    },
                    ctx,
                ) => {
                    const validateCollectiveAgreementPerUnitMin = (
                        message: string,
                    ) => {
                        if (
                            collectiveAgreementPerUnitMin &&
                            parseNumber(perUnitMin) <
                                parseNumber(collectiveAgreementPerUnitMin)
                        ) {
                            ctx.addIssue({
                                code: z.ZodIssueCode.custom,
                                message: message,
                                path: ['perUnitMin'],
                            });
                        }
                    };

                    if (!numberPerYear) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message:
                                'Anzahl der Gehälter pro Jahr muss einen positiven Wert ergeben',
                            path: ['numberPerYear'],
                        });
                    }

                    if (!equivalentHours) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message:
                                'Stundenbasis für Gehaltsangabe müssen ausgefüllt werden.',
                            path: ['equivalentHours'],
                        });
                    }

                    if (type === 'FIXED-SALARY') {
                        validateCollectiveAgreementPerUnitMin(
                            'Bruttofixgehalt muss mindestens so viel wie das KV-Mindestbruttogehalt sein.',
                        );
                    }

                    if (type === 'FROM-SALARY') {
                        validateCollectiveAgreementPerUnitMin(
                            'Mindestgehalt muss mindestens so viel wie das KV-Mindestbruttogehalt sein.',
                        );
                    }

                    if (type === 'RANGED-SALARY') {
                        validateCollectiveAgreementPerUnitMin(
                            'Bruttogehaltsspanne muss mindestens so viel wie das KV-Mindestbruttogehalt sein.',
                        );
                    }

                    if (!perUnitMin && !collectiveAgreementPerUnitMin) {
                        const minMessage = () => {
                            if (type === 'FIXED-SALARY') {
                                return 'Bruttofixgehalt muss ausgefüllt werden.';
                            }
                            if (type === 'FROM-SALARY') {
                                return 'Mindestgehalt muss ausgefüllt werden.';
                            }
                            return 'Bruttogehaltsspanne (Min) muss ausgefüllt werden.';
                        };

                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: minMessage(),
                            path: ['perUnitMin'],
                        });

                        if (
                            ['FROM-SALARY', 'FIXED-SALARY'].includes(
                                type as string,
                            )
                        ) {
                            ctx.addIssue({
                                code: z.ZodIssueCode.custom,
                                message:
                                    'KV-Mindestbruttogehalt muss ausgefüllt werden.',
                                path: ['collectiveAgreementPerUnitMin'],
                            });
                        }
                    }

                    if (type === 'RANGED-SALARY') {
                        if (!perUnitMax) {
                            ctx.addIssue({
                                code: z.ZodIssueCode.custom,
                                message:
                                    'Maximalgehalt muss ausgefüllt werden.',
                                path: ['perUnitMax'],
                            });
                        }

                        if (
                            perUnitMin &&
                            perUnitMax &&
                            parseNumber(perUnitMin) >= parseNumber(perUnitMax)
                        ) {
                            ctx.addIssue({
                                code: z.ZodIssueCode.custom,
                                message:
                                    'Maximalgehalt muss großer als das Mindestgehalt sein',
                                path: ['perUnitMax'],
                            });
                        }
                        if (
                            collectiveAgreementPerUnitMin &&
                            perUnitMax &&
                            parseNumber(collectiveAgreementPerUnitMin) >
                                parseNumber(perUnitMax)
                        ) {
                            ctx.addIssue({
                                code: z.ZodIssueCode.custom,
                                message:
                                    'Maximalgehalt muss großer als das KV-Mindestbruttogehalt sein',
                                path: ['perUnitMax'],
                            });
                        }
                    }

                    if (isBonusAvailable) {
                        if (
                            averageBonusPerUnit &&
                            parseNumber(averageBonusPerUnit) <= 0
                        ) {
                            ctx.addIssue({
                                code: z.ZodIssueCode.custom,
                                message:
                                    validationMessages.averageBonusPerUnitPositive,
                                path: ['averageBonusPerUnit'],
                            });
                        }
                    }
                },
            ),
        skills: zJamFormStepDataSkills,
        title: z.string().min(1, 'Titel des Inserats muss ausgefüllt werden'),

        workplaceType: z
            .enum(['ON-SITE', 'HYBRID', 'REMOTE', ''], {
                invalid_type_error: 'Arbeitsmodell muss ausgefüllt werden.',
                required_error: 'Arbeitsmodell muss ausgefüllt werden.',
            })
            .refine(
                (value) => !!value,
                'Arbeitsmodell muss ausgefüllt werden.',
            ),
    })
    .refine(
        (data) => {
            if (
                data.employmentRelationship.jobEntryHasDate &&
                data.employmentRelationship.temporaryJob
            ) {
                return zJobEntryDateIsBeforeTemporaryUntilDate(
                    data.employmentRelationship.jobEntryDate,
                    data.employmentRelationship.temporaryUntilDate,
                );
            }
            return true;
        },
        {
            message: validationMessages.dateConstraintStartDateMismatch,
            path: ['employmentRelationship.jobEntryDate'],
        },
    )
    .refine(
        (data) => {
            if (
                data.employmentRelationship.jobEntryHasDate &&
                !data.employmentRelationship.jobEntryDate
            ) {
                return true;
            }

            if (data.meta?.status !== 'DRAFT') {
                return true;
            }

            return (
                data.employmentRelationship.jobEntryDate === '' ||
                isDateInFuture(data.employmentRelationship.jobEntryDate, true)
            );
        },
        {
            message: validationMessages.dateConstraintNotPast,
            path: ['employmentRelationship.jobEntryDate'],
        },
    )
    .refine(
        (data) => {
            if (
                data.employmentRelationship.jobEntryHasDate &&
                data.employmentRelationship.temporaryJob
            ) {
                return zJobEntryDateIsBeforeTemporaryUntilDate(
                    data?.employmentRelationship.jobEntryDate,
                    data?.employmentRelationship.temporaryUntilDate,
                );
            }
            return true;
        },
        {
            message: validationMessages.dateConstraintEndDateMismatch,
            path: ['employmentRelationship.temporaryUntilDate'],
        },
    )
    .refine(
        (data) => {
            if (
                data.employmentRelationship.temporaryJob &&
                !data.employmentRelationship.temporaryUntilDate
            ) {
                return true;
            }

            if (data.meta?.status !== 'DRAFT') {
                return true;
            }

            return (
                data.employmentRelationship.jobEntryDate === '' ||
                (!data.employmentRelationship.temporaryJob &&
                    data.employmentRelationship.temporaryUntilDate === '') ||
                isDateInFuture(data.employmentRelationship.temporaryUntilDate)
            );
        },
        {
            message: validationMessages.dateConstraintFuture,
            path: ['employmentRelationship.temporaryUntilDate'],
        },
    )
    .refine(
        (data) => {
            return data.language === 'EN'
                ? data.contentSalutation !== 'FORMAL'
                : true;
        },
        {
            message: 'Inhaltliche Anrede muss ausgefüllt werden.',
            path: ['contentSalutation'],
        },
    );

export const stepperSectionsData: JamFormContainerStepperSectionConfig[] = [
    {
        fields: ['contentSalutation'],
        title: 'Inhaltssprache und Anrede',
    },
    {
        fields: ['title', 'jobFields', 'jobLevels', 'workplaceType'],
        title: 'Basisdaten',
    },
    {
        fields: ['locations'],
        title: 'Dienstort(e)',
    },
    {
        fields: ['employmentTypes'],
        title: 'Anstellungsart(en)',
    },
    {
        fields: [
            'salary.collectiveAgreement',
            'salary.collectiveAgreementPerUnitMin',
            'salary.currency',
            'salary.isBonusAvailable',
            'salary.averageBonusPerUnit',
            'salary.unit',
            'salary.perUnitMin',
            'salary.perUnitMax',
            'salary.numberPerYear',
            'salary.equivalentHours',
            'salary.type',
        ],
        title: 'Lohn/Gehalt',
    },
    {
        fields: ['skills'],
        initiallyVisible: true,
        optional: true,
        title: 'Kenntnisse',
    },
    {
        fields: ['languageSkills'],
        optional: true,
        title: 'Sprachen',
    },
    {
        fields: ['education'],
        optional: true,
        title: 'Ausbildung',
    },
];

export const jamFormStepDataValidationErrorsMap: { [key: string]: string } = {
    'attributes.contentSalutation': 'contentSalutation',
    'attributes.education': 'education',
    'attributes.employmentTypes': 'employmentTypes',
    'attributes.jobEntryDate': 'employmentRelationship.jobEntryDate',
    'attributes.jobFields': 'jobFields',
    'attributes.jobLevels': 'jobLevels',
    'attributes.jobOpenings': 'jobOpenings',
    'attributes.language': 'language',
    'attributes.package': 'product',
    'attributes.referenceCode': 'reference',
    'attributes.salaryAverageBonusPerUnit': 'salary.averageBonusPerUnit',
    'attributes.salaryCollectiveAgreement': 'salary.collectiveAgreement',
    'attributes.salaryCollectiveAgreementPerUnitMin':
        'salary.collectiveAgreementPerUnitMin',
    'attributes.salaryCurrency': 'salary.currency',
    'attributes.salaryEquivalentHours': 'salary.equivalentHours',
    'attributes.salaryIsBonusAvailable': 'salary.isBonusAvailable',
    'attributes.salaryNumberPerYear': 'salary.numberPerYear',
    'attributes.salaryPerUnitMax': 'salary.perUnitMax',
    'attributes.salaryPerUnitMin': 'salary.perUnitMin',
    'attributes.salaryType': 'salary.type',
    'attributes.salaryUnit': 'salary.unit',
    'attributes.skills': 'skills',
    'attributes.temporaryJob': 'employmentRelationship.temporaryJob',
    'attributes.temporaryUntilDate':
        'employmentRelationship.temporaryUntilDate',
    'attributes.title': 'title',
    'attributes.workplaceType': 'workplaceType',
};

export const jamFormStepDataServiceSetup: JamServiceJobAdsSetup<JamFormStepData> =
    {
        inbound: (data: JamFormStepData): JobAdAttributes => {
            return {
                contentSalutation: data.contentSalutation,
                education: data.education,
                employmentTypes:
                    data.employmentTypes?.map(
                        (
                            item: JamFormStepDataEmploymentType,
                        ): JobAdEmploymentType => {
                            return {
                                homeofficeDaysPercentage:
                                    data.workplaceType === 'HYBRID'
                                        ? item.homeofficeDaysPercentage || null
                                        : null,
                                overtimeHandling:
                                    item.overtimeHandling ?? 'NO-INFORMATION',
                                shiftWork: item.shiftWork,
                                travelingIsRequired: item.travelingIsRequired,
                                travelingPercentage:
                                    item.travelingPercentage || null,
                                type: item.type || null,
                                weeklyHoursMax:
                                    item.worktime === 'FULL-TIME'
                                        ? null
                                        : item.weeklyHoursMax
                                          ? useDecimalConverter().convertCommaToDot(
                                                item.weeklyHoursMax as string,
                                            )
                                          : null,
                                weeklyHoursMin:
                                    useDecimalConverter().convertCommaToDot(
                                        item.weeklyHoursMin as string,
                                    ),
                                workdaysAreFlexible: item.workdaysAreFlexible,
                                workdaysInWeek: item.workdaysInWeek,
                                worktime: item.worktime || null,
                            };
                        },
                    ) ?? [],
                jobEntryDate: !data.employmentRelationship.jobEntryHasDate
                    ? null
                    : useFormatStringDate().localToServer(
                          data.employmentRelationship.jobEntryDate ?? undefined,
                      ),
                jobFields: data.jobFields ?? [],
                jobLevels: data.jobLevels ?? [],
                jobOpenings: parseInt(data.jobOpenings ?? '1'),
                language: data.language ?? 'DE',
                languageSkills: data.languageSkills,
                locations: data.locations.map((location) => {
                    return {
                        id: location.id,
                        name: location.name,
                        number: location.number ?? '',
                        postalCode: location.postalCode ?? '',
                        street: location.street ?? '',
                    };
                }),
                package:
                    data.product === 'PLUS-STARTER' ? 'PLUS' : data.product,
                referenceCode: data.referenceCode,
                salaryAverageBonusPerUnit: !data.salary.isBonusAvailable
                    ? null
                    : useDecimalConverter().convertCommaToDot(
                          data.salary.averageBonusPerUnit,
                      ),
                salaryCollectiveAgreement: data.salary.collectiveAgreement,
                salaryCollectiveAgreementPerUnitMin:
                    useDecimalConverter().convertCommaToDot(
                        data.salary.collectiveAgreementPerUnitMin,
                    ),
                salaryCurrency: data.salary.currency,
                salaryEquivalentHours: useDecimalConverter().convertCommaToDot(
                    data.salary.equivalentHours,
                ),
                salaryIsBonusAvailable: data.salary.isBonusAvailable,
                salaryNumberPerYear: data.salary.numberPerYear || 0,
                salaryPerUnitMax:
                    data.salary.type !== 'RANGED-SALARY'
                        ? null
                        : useDecimalConverter().convertCommaToDot(
                              data.salary.perUnitMax,
                          ),
                salaryPerUnitMin: useDecimalConverter().convertCommaToDot(
                    data.salary.perUnitMin,
                ),
                salaryType: data.salary.type,
                salaryUnit: data.salary.unit,
                skills: data.skills,
                temporaryJob: data.employmentRelationship.temporaryJob,
                temporaryUntilDate: !data.employmentRelationship.temporaryJob
                    ? null
                    : useFormatStringDate().localToServer(
                          data.employmentRelationship.temporaryUntilDate ??
                              undefined,
                      ),
                title: data.title,
                workplaceType: data.workplaceType || null,
            };
        },
        outbound: (attributes: JobAdAttributes): JamFormStepData => {
            return {
                contentSalutation: attributes.contentSalutation ?? '',
                education: attributes.education ?? [],
                employmentRelationship: {
                    jobEntryDate:
                        useFormatStringDate().serverToLocal(
                            attributes.jobEntryDate ?? undefined,
                        ) ?? null,
                    jobEntryHasDate: !!attributes.jobEntryDate,
                    temporaryJob: attributes.temporaryJob ?? false,
                    temporaryUntilDate:
                        useFormatStringDate().serverToLocal(
                            attributes.temporaryUntilDate ?? undefined,
                        ) ?? null,
                },
                employmentTypes:
                    attributes.employmentTypes?.map(
                        (
                            item: JobAdEmploymentType,
                        ): JamFormStepDataEmploymentType => {
                            return {
                                homeofficeDaysPercentage:
                                    attributes.workplaceType === 'HYBRID'
                                        ? item.homeofficeDaysPercentage ?? ''
                                        : null,
                                overtimeHandling:
                                    item.overtimeHandling ?? 'NO-INFORMATION',
                                shiftWork: item.shiftWork,
                                travelingIsRequired: item.travelingIsRequired,
                                travelingPercentage:
                                    (item.travelingIsRequired &&
                                        item.travelingPercentage) ||
                                    null,
                                type: item.type,
                                weeklyHoursMax: item.weeklyHoursMax
                                    ? useDecimalConverter().convertDotToComma(
                                          item.weeklyHoursMax,
                                      ) ?? ''
                                    : '',
                                weeklyHoursMin:
                                    useDecimalConverter().convertDotToComma(
                                        item.weeklyHoursMin,
                                    ) ?? '',
                                workdaysAreFlexible: item.workdaysAreFlexible,
                                workdaysInWeek: item.workdaysInWeek,
                                worktime: item.worktime,
                            };
                        },
                    ) ?? [],
                jobFields:
                    attributes.jobFields?.map((value) => `${value}`) ?? [],
                jobLevels:
                    attributes.jobLevels?.map((value) => `${value}`) ?? [],
                jobOpenings: (attributes.jobOpenings ?? 1).toString(),
                language: attributes.language ?? 'DE',
                languageSkills:
                    attributes.languageSkills?.map((skill) => {
                        if (skill.mustHave === null) {
                            skill.mustHave = false;
                        }
                        return skill;
                    }) ?? [],
                locations:
                    attributes.locations?.map((location) => {
                        return {
                            id: location.id,
                            name: location.name,
                            number: location.number ?? '',
                            postalCode: location.postalCode ?? '',
                            street: location.street ?? '',
                        };
                    }) ?? [],
                product: attributes.package ?? 'BASIC',
                referenceCode: attributes.referenceCode ?? '',
                salary: {
                    averageBonusPerUnit:
                        useDecimalConverter().convertDotToComma(
                            attributes.salaryAverageBonusPerUnit,
                        ) ?? '',
                    collectiveAgreement:
                        attributes.salaryCollectiveAgreement ?? '',
                    collectiveAgreementPerUnitMin:
                        useDecimalConverter().convertDotToComma(
                            attributes.salaryCollectiveAgreementPerUnitMin,
                        ) ?? '',
                    currency: attributes.salaryCurrency ?? 'EUR',
                    equivalentHours:
                        useDecimalConverter().convertDotToComma(
                            attributes.salaryEquivalentHours,
                        ) ?? '',
                    isBonusAvailable:
                        attributes.salaryIsBonusAvailable ?? false,
                    numberPerYear: attributes.salaryNumberPerYear ?? undefined,
                    perUnitMax:
                        useDecimalConverter().convertDotToComma(
                            attributes.salaryPerUnitMax,
                        ) ?? '',
                    perUnitMin:
                        useDecimalConverter().convertDotToComma(
                            attributes.salaryPerUnitMin,
                        ) ?? '',
                    type: attributes.salaryType ?? 'FIXED-SALARY',
                    unit: attributes.salaryUnit ?? 'MONTHLY',
                },
                skills: attributes.skills ?? [],
                title: attributes.title ?? '',
                workplaceType: attributes.workplaceType ?? '',
            };
        },
        validationErrorsMap: jamFormStepDataValidationErrorsMap,
    };

export const jamFormStepDataDefaultData: JamFormStepData = {
    contentSalutation: '',
    education: [],
    employmentRelationship: {
        jobEntryDate: '',
        jobEntryHasDate: false,
        temporaryJob: false,
        temporaryUntilDate: '',
    },
    employmentTypes: [
        {
            homeofficeDaysPercentage: null,
            overtimeHandling: 'NO-INFORMATION',
            shiftWork: false,
            travelingIsRequired: false,
            travelingPercentage: null,
            type: '',
            weeklyHoursMax: '',
            weeklyHoursMin: '',
            workdaysAreFlexible: false,
            workdaysInWeek: ['MON', 'TUE', 'WED', 'THU', 'FRI'],
            worktime: null,
        },
    ],
    jobFields: [],
    jobLevels: [],
    jobOpenings: '1',
    language: 'DE',
    languageSkills: [],
    locations: [
        {
            id: '',
            name: '',
            number: '',
            postalCode: '',
            street: '',
        },
    ],
    product: 'BASIC',
    referenceCode: '',
    salary: {
        averageBonusPerUnit: '',
        collectiveAgreement: '',
        collectiveAgreementPerUnitMin: '',
        currency: 'EUR',
        equivalentHours: '',
        isBonusAvailable: false,
        numberPerYear: 14,
        perUnitMax: '',
        perUnitMin: '',
        type: null,
        unit: 'MONTHLY',
    },
    skills: [],
    title: '',
    workplaceType: '',
};

export const useJamFormStepData = (defaultData?: JamFormStepData) => {
    if (defaultData?.data) {
        if (
            !defaultData.data?.employmentTypes ||
            defaultData.data?.employmentTypes.length === 0
        ) {
            defaultData.data.employmentTypes = [
                {
                    shiftWork: false,
                    travelingIsRequired: false,
                    type: 'FIXED-EMPLOYMENT',
                    weeklyHoursMin: '',
                    workdaysAreFlexible: false,
                    workdaysInWeek: ['MON', 'TUE', 'WED', 'THU', 'FRI'],
                    worktime: 'FULL-TIME',
                },
            ];
        }
        if (
            !defaultData.data?.locations ||
            defaultData.data?.locations.length === 0
        ) {
            defaultData.data.locations = [
                {
                    id: '',
                    name: '',
                    number: '',
                    postalCode: '',
                    street: '',
                },
            ];
        }
    }
    const { data, errors, hasError, submit, validate } =
        useJamForm<JamFormStepData>({
            defaultData: {
                ...structuredClone(jamFormStepDataDefaultData),
                ...defaultData,
            },
            validationRules: [zJamFormStepData],
        });

    const create = async (nextPage: string) => {
        await submit(
            `/api/manage-job-ads/external/job-ads`,
            'post',
            useJamFormSteps().getAjaxSetup('', async (response) => {
                if (response.data?.meta?.id) {
                    useDuplicateJob().setDuplicatedJobId(
                        response.data?.meta?.id,
                    );
                    await navigateTo(
                        `/p/jobs/${response.data?.meta?.id}/${nextPage}`,
                    );
                }
            }) as any,
        );
    };

    const update = async (
        id: number,
        nextPage?: string,
        onSuccess?: (response: JamServiceResponse<JamForm>) => void,
    ) => {
        await submit(
            `/api/manage-job-ads/shared/job-ads/${id}/step-data`,
            'patch',
            useJamFormSteps().getAjaxSetup(nextPage, onSuccess) as any,
        );
    };

    return {
        create,
        data,
        errors,
        hasError,
        infoText,
        submit,
        update,
        validate,
    };
};
